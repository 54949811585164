import {isSkFieldValueFilled, SkFormValues} from "../../components/SkForms/SkForm";
import ApiAnswerPostModel from "../../services/api/applications/ApiAnswerModel";

const AnswersFromFormValues: (values: SkFormValues, application: string, disabled?: string[]) => ApiAnswerPostModel[] = (values, application,disabled) => {
  const answers: ApiAnswerPostModel[] = [];

  Object.keys(values).forEach(questionId => {
    if (disabled?.some(id => id === questionId)) return;

    const value = values[questionId];
    // IMPORTANT don't submit empty values
    if (isSkFieldValueFilled(value)) {
      if (Array.isArray(value)) {
        (value as string[]).forEach((v,i) => {
          answers.push({
            question: questionId,
            answer_number: i+1,
            answer_value: v,
            application: application,
          });
        });
      } else {
        answers.push({
          question: questionId,
          answer_number: 1,
          answer_value: `${value}`,
          application: application,
        });
      }
    }

  });

  return answers;
};

export default AnswersFromFormValues;