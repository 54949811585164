import {
  Alert,
  Button, ButtonGroup, Card,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Modal, OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip
} from "react-bootstrap";
import Icon from "../../../../helpers/components/Icon";
import {
  ArrowRepeat,
  Columns,
  EmojiFrown,
  Eye,
  EyeSlash, FileArrowDownFill,
  FileEarmarkArrowDownFill,
  Filter, PersonCircle,
  Search, SortDown,
  SortUp,
  X
} from "react-bootstrap-icons";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {pageStoreContext} from "../../../../pages/backoffice/BackApplicationsPage";
import Loading from "../../../../pages/Loading";
import ApplicationDropdownToggle from "./ApplicationDropdownToggle";
import {debounce} from "debounce";
import ErrorBoundary from "../../../ErrorBoundary";
import {runInAction} from "mobx";
import {
  FieldDisplayFormat,
  FieldRecommendation
} from "../../../../services/api/back/campaigns/applications/ApiApplicationField";
import useFileUploader from "../../../../stores/singletones/FileUploaderStore";
import BackApplicationsPageStore, {Column, ConfiguredFilter, TableCell} from "../../../../stores/backoffice/pages/BackApplicationsPageStore";
import emptyCellCode from "../../../../const/emptyCellCode";
import ApiApplicationFilter from "../../../../services/api/back/campaigns/applications/ApiApplicationFilter";
import {ButtonVariant} from "react-bootstrap/types";
import ApiBackCampaignsRecommendationsGet, {ApiBackCampaignsRecommendationsGetSource} from "../../../../services/api/back/campaigns/recommendations/ApiBackCampaignsRecommendationsGet";
import {
  ApiRecommendationBackModel,
} from "../../../../services/api/recommendations/ApiRecommendationModel";
import RecommendationRequestStatusBadge from "../../../RecommendationRequestStatusBadge";
import RecommendationResponseStatusBadge from "../../../RecommendationResponseStatusBadge";
import useHandleDropdownToggle from "../../../../helpers/hooks/useHandleDropdownToggle";

const filterVariant:ButtonVariant = "dark";

const Report:React.FC = observer(() => {
  const pageStore = useContext(pageStoreContext);

  if (!pageStore) return null;
  return <Button className={"ml-3"} variant={"outline-primary"} onClick={() => pageStore.handleReport()} disabled={pageStore?.reportState !== "ok"}>
    <span className={"d-sm-none d-lg-inline"}>Report</span> {pageStore.reportState === "ok" ? <FileArrowDownFill/> : <ArrowRepeat className={"animation-spin"}/>}
  </Button>;
});

const SortBy:React.FC = observer(() => {
  const pageStore = useContext(pageStoreContext);
  const [showSortByDD, setShowSortByDD] = useState(false);
  const handleToggle = useHandleDropdownToggle(setShowSortByDD);

  if (!pageStore) return null;
  return <>
    <Dropdown show={showSortByDD} onToggle={handleToggle} as={ButtonGroup}>
      <Dropdown.Toggle id="applications-table-sort-by" variant={"outline-primary"} className={"btn-overflow"}>
        Sort: {pageStore.sortField?.name}
      </Dropdown.Toggle>
      {pageStore.applicationsConfig?.available_fields?.length ? (
        <Dropdown.Menu className={"dropdown-scroll"}>
          {pageStore.applicationsConfig.available_fields.map(field => <>
            <Dropdown.Item key={field.id} as={"button"} onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              pageStore?.setSortFieldId(field.id);
              setShowSortByDD(false);
            }}>
              {field.name}
            </Dropdown.Item>
          </>)}
        </Dropdown.Menu>
      ) : null}
    </Dropdown>
    <OverlayTrigger
      placement={"bottom"}
      overlay={
        <Tooltip id={"sort-mode"}>
          {pageStore.sort === "asc" ? "Sort ascending" : "Sort descending"}
        </Tooltip>
      }
    >
      <Button variant={"outline-primary"} onClick={() => pageStore?.switchSort()}>
        {pageStore.sort === "asc" ? <SortUp/> : <SortDown/>}
      </Button>
    </OverlayTrigger>
  </>;
});

const AddFilter:React.FC = observer(() => {
  const pageStore = useContext(pageStoreContext);
  if (!pageStore) return null;

  const [showAddFilerDD, setShowAddFilerDD] = useState(false);
  const handleToggle = useHandleDropdownToggle(setShowAddFilerDD);

  return <>
    <Dropdown show={showAddFilerDD} onToggle={handleToggle} as={ButtonGroup}>
      <Dropdown.Toggle variant={pageStore.isHasConfiguredFilters ? filterVariant : "outline-primary"} id="applications-table-filters">
        <span className={"d-sm-none d-lg-inline"}>{pageStore.isHasConfiguredFilters ? "Add Filter" : "Filter"}</span> <Filter/>
      </Dropdown.Toggle>

      {pageStore.applicationsConfig?.available_filters?.length ? (
        <Dropdown.Menu>
          {pageStore.applicationsConfig.available_filters.map(af => pageStore?.configuredFilters.find(cf => cf.key === af.key) ? (
            <Dropdown.Item key={af.key} as={"button"} disabled={true}>
              {af.name}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item key={af.key} as={"button"} onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              pageStore?.setFilter(af.key, af.variants[0].id);
              setShowAddFilerDD(false);
            }}>
              {af.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      ) : null}
    </Dropdown>
  </>;
});

interface FilterButtonProps extends React.HTMLProps<HTMLDivElement> {
  cf: ConfiguredFilter
}

const FilterButton: React.FC<FilterButtonProps> = observer(({cf, ...rest}) => {
  const pageStore = useContext(pageStoreContext);

  const filter:ApiApplicationFilter|undefined = useMemo(() => pageStore?.applicationsConfig?.available_filters?.find(f => f.key === cf.key), [pageStore?.applicationsConfig?.available_filters, cf.key]);

  const filterCurrentValue = useMemo(() => filter?.variants.find(v => v.id === cf.variantId)?.variant_value, [cf.variantId, filter]);

  const [show, setShow] = useState(false);
  const handleToggle = useHandleDropdownToggle(setShow);

  if (!pageStore) return null;

  return filter ? <div {...rest}>
    <Dropdown show={show} onToggle={handleToggle}>
      <Dropdown.Toggle variant={filterVariant} id="applications-table-filters">
        <span style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: 300,
          display: "inline-block",
          verticalAlign: "middle"
        }}>
          {filter.name}: {filterCurrentValue}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className={"dropdown-scroll"}>
        {filter.variants.map(v => {
          if (v.id === cf.variantId) {
            return <Dropdown.Item key={v.id} disabled={true}>
              {v.variant_value}
            </Dropdown.Item>;
          } else {
            return <Dropdown.Item key={v.id} as={"button"} onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              pageStore?.setFilter(filter?.key, v.id);
              setShow(false);
            }}>
              {v.variant_value}
            </Dropdown.Item>;
          }
        })}
        <Dropdown.Divider/>
        <Dropdown.Item onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          pageStore?.clearFilter(filter?.key);
          setShow(false);
        }}>
          <X/> Remove filter
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div> : <div {...rest}>
    <Button variant={filterVariant}>
      ...
    </Button>
  </div>;
});

const FiltersBar: React.FC = observer(() => {
  const pageStore = useContext(pageStoreContext);
  const [show, setShow] = useState(false);

  if (!pageStore) return null;

  return pageStore.configuredFilters?.length > 0 ? <div className={"d-flex"} style={{flexWrap: "wrap"}}>
    {pageStore.configuredFilters.map(cf => <FilterButton cf={cf} key={cf.key} className={"mr-3 mt-3"}/>)}
    <Button variant={filterVariant} onClick={() => setShow(true)} className={"mt-3"}>
      <X/> Clear Filters
    </Button>
    <Modal
      show={show}
      onHide={() => setShow(false)}
      centered
    >
      <Modal.Body>
        Clear all filters?
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={() => {
          pageStore.clearFilters();
          setShow(false);
        }}>
          <X/> Clear Filters
        </Button>
        <Button onClick={() => setShow(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </div> : null;
});

const ColumnsSelector:React.FC = observer(() => {
  const pageStore = useContext(pageStoreContext);
  if (!pageStore) return null;

  const [showColumnsDD, setShowColumnsDD] = useState(false);

  return <>
    <Dropdown
      show={showColumnsDD}
      onToggle={(isOpen, e, m) => {
        if (isOpen) {
          setShowColumnsDD(true);
        } else {
          if (m.source !== "select") {
            setShowColumnsDD(false);
          }
        }
      }}
      as={ButtonGroup}
    >
      <Dropdown.Toggle variant={"outline-primary"} id="applications-table-columns">
        <span className={"d-sm-none d-lg-inline"}>Columns</span> <Columns/>
      </Dropdown.Toggle>
      <Dropdown.Menu className={"applications-table-columns-dropdown"}>
        {!(pageStore.columns?.length) ? (
          <Dropdown.Item>Loading...</Dropdown.Item>
        ) : (
          <>
            <Dropdown.Item key={"hideall"} as={"button"} className={"dropdown-item-full-width"} onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              pageStore?.toggleHideAll();
            }}>
              {pageStore.isAllColumnsHidden ? <EyeSlash/> : <Eye className={"text-primary"}/>}
              <span className={`ml-3 ${pageStore.isAllColumnsHidden ? "text-black-50" : ""}`}>Everything</span>
            </Dropdown.Item>
            {pageStore.columnGroups?.map(cg => <React.Fragment key={cg.slug}>
              <Dropdown.Divider/>
              <Dropdown.Item as={"button"} className={"dropdown-item-full-width"} onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                pageStore.toggleColumnGroup(cg);
              }}>
                {cg.hidden ? <EyeSlash/> : <Eye className={"text-primary"}/>}
                <span className={`ml-3 ${cg.hidden ? "text-black-50" : ""}`}>
                  <b>{cg.name}</b>
                </span>
              </Dropdown.Item>
              {cg.columns?.map(col =>
                <Dropdown.Item key={`${cg.slug}_${col.id}`} as={"button"} onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  pageStore.toggleColumn(col);
                }}>
                  {col.hidden ? <EyeSlash/> : <Eye className={"text-primary"}/>}
                  <span className={`ml-3 ${col.hidden ? "text-black-50" : ""}`}>
                    {col.name}
                  </span>
                </Dropdown.Item>)}
            </React.Fragment>)}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  </>;
});

const RecommendationData:React.FC<{
  campaignPk?: string;
  recommendationBlindId: string;
}> = ({campaignPk, recommendationBlindId}) => {
  const [state, setState] = useState("init");
  const [error, setError] = useState("");
  const [data, setData] = useState<ApiRecommendationBackModel|undefined>();

  useEffect(() => {
    if (!campaignPk) {
      setError("No campaign id found");
      return;
    }

    ApiBackCampaignsRecommendationsGet(campaignPk, recommendationBlindId).then((response) => {
      try {
        if (response.data && response.data.id && response.data.recommender) {
          setData(response.data);
        } else {
          setError("No data provided");
        }
      } catch (e) {
        setError("An error occurred during recommendation parsing");
      }
    }, (e) => {
      console.log(e);
      setError("An error occurred during recommendation loading");
    }).finally(() => setState("ok"));

    return () => ApiBackCampaignsRecommendationsGetSource?.cancel();
  }, []);

  return <Loading loading={state === "init"} style={{height: 200}}>
    {error ? (
      <Alert variant={"danger"}>{error}</Alert>
    ) : (
      data ? (
        <>
          <div className={"mb-2"}><small><b>Request status:</b> <RecommendationRequestStatusBadge requestState={data.request_state}/> {data.request_sent_at}</small></div>
          <div className={"mt-2 mb-4"}><small><b>Response status:</b> <RecommendationResponseStatusBadge responseState={data.response_state}/> {data.response_sent_at}</small></div>

          {data.recommendation_text ? (
            <Card>
              <Card.Header className={"d-flex align-items-center"}>
                <PersonCircle style={{fontSize: 32}} className={"text-secondary mr-3"}/> <b className={"mr-2"}>{data.recommender?.position} {data.recommender?.first_name} {data.recommender?.last_name}</b> ({data.recommender?.email})
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  {data.recommendation_text}
                </Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <>
              <p>Recommendation requested from {data.recommender?.position} {data.recommender?.first_name} {data.recommender?.last_name} ({data.recommender?.email})</p>
            </>
          )}
        </>
      ) : (
        <Alert variant={"danger"}>No data avaible</Alert>
      )
    )}
  </Loading>;
};

const AppTableRecommendationButton:React.FC<{
  cell: TableCell<FieldRecommendation>;
  idx: string;
}> = observer(({cell, idx}) => {
  const pageStore = useContext(pageStoreContext);
  const campaignPk = pageStore?.backAppStore.campaignId;
  const fileUploaderStore = useFileUploader();
  const recommendation = cell.data;
  const files = useMemo(() => recommendation.upload ? [recommendation.upload] : [], [recommendation.upload]);
  const [show, setShow] = useState(false);

  if (files.length) {
    return <OverlayTrigger
      placement="right"
      delay={{ show: 100, hide: 0 }}
      overlay={
        <Tooltip id={`${idx}_overlay`}>
          Show {files.length > 1 ? `${files.length} files` : "file"}
        </Tooltip>
      }
    >
      <Button variant={"link"} className={`text-${recommendation.style}`} onClick={() => {
        fileUploaderStore.openFilesDownloadDialog(files);
      }}>
        <Icon icon={recommendation.icon?.replace("bi-","")}/> {recommendation.text}
      </Button>
    </OverlayTrigger>;
  } else {
    return <OverlayTrigger
      placement="right"
      delay={{ show: 100, hide: 0 }}
      overlay={
        <Tooltip id={`${idx}_overlay`}>
          Show recommendation status
        </Tooltip>
      }
    >
      <>
        <Button variant={"link"} className={`text-${recommendation.style}`} onClick={() => setShow(true)}>
          <Icon icon={recommendation.icon?.replace("bi-","")}/> {recommendation.text}
        </Button>
        <Modal show={show} onHide={() => setShow(false)} centered size={"xl"} className={"sk-back-recommendation-view"}>
          <Modal.Body>
            <div style={{overflow: "auto", maxHeight: 400}}>
              <RecommendationData campaignPk={campaignPk} recommendationBlindId={cell.data.id}/>
            </div>
            <div className={"d-flex justify-content-end mt-3"}>
              <Button variant="outline-primary" onClick={() => setShow(false)}>
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </OverlayTrigger>;
  }
});

const AppTableFilesButton:React.FC<{
  cell: TableCell<string>;
  idx: string;
}> = observer(({cell, idx}) => {
  const fileUploaderStore = useFileUploader();
  const files = useMemo(() => `${cell.data}`.split(","), [cell.data]);

  if (cell.data === emptyCellCode || !files || !files.length || !files.join("")) return <>{emptyCellCode}</>;

  return <>
    <OverlayTrigger
      placement="right"
      delay={{ show: 100, hide: 0 }}
      overlay={
        <Tooltip id={`${idx}_overlay`}>
          Show {files.length > 1 ? `${files.length} files` : "file"}
        </Tooltip>
      }
    >
      <Button variant={"link"} onClick={() => {
        fileUploaderStore.openFilesDownloadDialog(files);
      }}>
        <FileEarmarkArrowDownFill/>
      </Button>
    </OverlayTrigger>
  </>;
});

const SearchBar:React.FC = observer(() => {
  const pageStore = useContext(pageStoreContext);
  if (!pageStore) return null;

  const handleOnChangeSearch = useCallback((e:React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    runInAction(() => pageStore && (pageStore.query = v));
  }, []);

  return <div className={"flex-grow-1"}>
    <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text>
          <Search/>
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl id="applications_search" placeholder="Search applicants" value={pageStore.query} onChange={handleOnChangeSearch}/>
    </InputGroup>
  </div>;
});

const ApplicationTableHeaderCell:React.FC<{column: Column}> = observer(({column}) => {
  const pageStore = useContext(pageStoreContext);

  const nameSuffix = useMemo(() => column.id === pageStore?.sortFieldId ? pageStore?.sort === "asc" ? <SortUp className={"ml-2"}/> : <SortDown className={"ml-2"}/> : <SortUp className={"ml-2 invisible"}/>, [pageStore?.sortFieldId, pageStore?.sort]);
  const handleSort = useCallback(() => pageStore?.handleSortClick(column.id), []);
  const handleHide = useCallback(() => pageStore?.hiddenColumns.push(column.id), []);

  if (!pageStore) return null;

  return <th>
    <div className={"d-flex align-items-center"}>
      <Button
        variant={"link"}
        size={"sm"}
        className={"text-left pl-0 pr-0"}
        onClick={handleSort}
      >
        <small>{column.name}</small>
        {nameSuffix}
      </Button>
      <Button variant={"link"} size={"sm"} onClick={handleHide}><EyeSlash/></Button>
    </div>
  </th>;
});

const Applications:React.FC<{
  pageStore: BackApplicationsPageStore,
  loading: boolean,
}> = observer(({pageStore, loading}) => {
  const table = useRef<HTMLTableElement>(null);
  const getStickLeft = useCallback(() => {
    let s = table.current?.getBoundingClientRect().x;
    if (s === undefined) s = 1;
    return s >= 85 ? (s - 85) : 1;
  }, []);
  const [stickLeft, setStickLeft] = useState(0);
  useEffect(() => setStickLeft(getStickLeft()), [table.current, pageStore?.state]);
  const handleContainerScroll = useCallback(debounce(() => {
    const sl = getStickLeft();
    if (sl !== stickLeft) setStickLeft(sl);
  }, 50), []);
  useEffect(() => {
    window.addEventListener("resize", handleContainerScroll);
    return () => window.removeEventListener("resize", handleContainerScroll);
  }, []);

  if (!pageStore) return null;

  return <ErrorBoundary errorLevel={"ApplicationsTable-Applications"}>
    <div className="position-relative">
      <div className={"sk-applications-table-container sk-applications-table-container-stick"} onScroll={handleContainerScroll}>
        <div className={"d-flex"}>
          {/*Боковая таблица*/}
          <Table className={`sk-applications-table-side sk-applications-table-side_${stickLeft}`} bordered size={"md"} style={{left: stickLeft}}>
            <thead>
              <tr
                className={pageStore.allSelected ? "table-info" : ""}
                onClick={() => !loading && pageStore.toggleAllSelected()}
              >
                <th>
                  <div className={"d-flex align-items-center justify-content-end"}>
                    <Form.Check
                      type={"checkbox"}
                      id={"sk-applications-table-select-all"}
                      checked={pageStore.allSelected}
                      onChange={() => null}
                      disabled={loading}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className={loading ? "sk-disabled-loading" : ""}>
              {pageStore.applications.map(application =>
                <tr key={application.id} className={pageStore.selectedApplications.includes(application.id) ? "table-info" : ""}>
                  <td onClick={() => pageStore.toggleApplicationSelect(application.id)}>
                    <div className={"d-flex align-items-center"}>
                      <Dropdown>
                        <Dropdown.Toggle as={ApplicationDropdownToggle}/>
                        <Dropdown.Menu>
                          {pageStore.applicationsConfig?.available_individual_actions?.map(individualAction => (
                            <Dropdown.Item key={individualAction.slug} onClick={e => {
                              e.stopPropagation();
                              pageStore.showApplicationActionConfirmation(individualAction, application.id);
                            }}>
                              <Icon icon={individualAction.icon} className={"bi mr-2"}/> {individualAction.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      <Form.Check
                        type={"checkbox"}
                        id={`sk-applications-table-select-${application.id}`}
                        checked={pageStore.selectedApplications.includes(application.id)}
                        onChange={() => null}
                      />
                    </div>
                  </td>
                </tr>)}
            </tbody>
          </Table>
          {/*Основная таблица*/}
          <Table bordered size={"md"} ref={table} className={"sk-applications-table"}>
            <thead>
              <tr className={pageStore.allSelected ? "table-info" : ""}>
                {pageStore.visibleColumns.map(column => <ApplicationTableHeaderCell key={column.id} column={column}/>)}
              </tr>
            </thead>
            <tbody className={loading ? "sk-disabled-loading" : ""}>
              {pageStore.applicationsTable.map((row, i) =>
                <tr key={i} className={row.className}>
                  {row.cells.map((cell, ix) => {
                    // IMPORTANT be SURE to work out emptyCellCode
                    if (cell.displayFormat === FieldDisplayFormat.Badge) {
                      return <td key={`${ix}_${i}`} dangerouslySetInnerHTML={{__html: `${cell.data}`}}/>;
                    }

                    if (cell.displayFormat === FieldDisplayFormat.Recommendation) {
                      if (!cell.data || cell.data === emptyCellCode) {
                        return <td key={`${ix}_${i}`} style={{textAlign: "center"}}>{emptyCellCode}</td>;
                      } else {
                        return <td key={`${ix}_${i}`} style={{textAlign: "center"}}>
                          <AppTableRecommendationButton cell={cell as TableCell<FieldRecommendation>} idx={`${ix}_${i}`}/>
                        </td>;
                      }
                    }

                    if (cell.displayFormat === FieldDisplayFormat.File) {
                      return <td key={`${ix}_${i}`} style={{textAlign: "center"}}>
                        <AppTableFilesButton cell={cell as TableCell<string>} idx={`${ix}_${i}`}/>
                      </td>;
                    }

                    else {
                      // plain
                      return <td key={`${ix}_${i}`}>{`${cell.data}`}</td>;
                    }
                  })}
                </tr>)}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  </ErrorBoundary>;
});

const NoApplicationsMessage:React.FC = () => <Container className={"mt-3 mb-3"}>
  <Row>
    <Col>
      <p className={"text-center text-muted mt-5 mb-5"}>
        <div className={"mb-4"}>No applications found.</div>
        <h1><EmojiFrown/></h1>
      </p>
    </Col>
  </Row>
</Container>;

const LoadMoreApplicationButton:React.FC = observer(() => {
  const pageStore = useContext(pageStoreContext);
  if (!pageStore) return null;

  return <Container className={"mt-3 mb-3"}>
    <Row>
      <Col>
        <div className={"d-flex justify-content-center"}>
          <Button variant={"primary"} onClick={() => pageStore.loadMore()} disabled={pageStore.state !== "ok"}>
            Load more {pageStore.state !== "ok" && <Spinner size={"sm"} animation={"border"}/>}
          </Button>
        </div>
      </Col>
    </Row>
  </Container>;
});

// eslint-disable-next-line react/display-name
const ApplicationsTable:React.FC<{
  pageStore: BackApplicationsPageStore
}> = observer(({pageStore}) => {

  return <ErrorBoundary errorLevel={"ApplicationsTable"}>
    <Loading loading={pageStore.state === "init"}>
      <Container className={"mt-3 mb-3"}>
        <div className="sk-at-table-menu">
          <div className={"sk-at-table-actions"}>
            <ButtonGroup>
              <ColumnsSelector/>
              <SortBy/>
              <AddFilter/>
            </ButtonGroup>
            <Report/>
          </div>
          <div className={"sk-at-table-search"}>
            <SearchBar/>
          </div>
        </div>
        <Row>
          <Col>
            <FiltersBar/>
          </Col>
        </Row>
      </Container>

      {pageStore.applications.length > 0 ? <Applications pageStore={pageStore} loading={pageStore.state === "pending"}/> : <Loading loading={pageStore.state === "pending"} style={{height: 200}}><NoApplicationsMessage/></Loading>}

      {pageStore.canLoadMore && pageStore.state !== "pending" ? <LoadMoreApplicationButton/> : null}

    </Loading>
  </ErrorBoundary>;
});

export default ApplicationsTable;
