import React, {useEffect, useMemo} from "react";
import SkSubmitButtonProps from "./SkSubmitButtonProps";
import {Alert, Button, Spinner} from "react-bootstrap";
import {SkFormStoreChecker} from "./SkFormContexts";

/* eslint-disable react-hooks/rules-of-hooks */

/**
 * Эта кнопка, в отличии от SkSubmitButton, дизейблится, когда нечего сабмитить
 * НЕ УМЕЕТ accepts и требует находиться внутри SkFormStoreContext
 * @param props
 * @constructor
 */
const SkFormBlockSubmitButton:React.FC<SkSubmitButtonProps> = (props) => {
  const disabled = useMemo(() => props.disabled || props.formikProps.isSubmitting || !props.formikProps.isValid, [props.disabled, props.formikProps.isSubmitting, props.formikProps.isValid]);
  const isInvalid = !props.formikProps.isValid;


  return <SkFormStoreChecker name={"SkFormBlockSubmitButton"}>
    {(formStore) => {
      const hasChanges = useMemo(() => formStore.questionsChanged !== 0, [formStore.questionsChanged]);

      return <>
        <Button className={"flex-grow-1"} variant="primary" type="submit" disabled={disabled || !hasChanges} block size="lg">
          {props.formikProps.isSubmitting
            ? <><Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            /></>
            : props.caption
          }
        </Button>
        {!hasChanges && !props.formikProps.isSubmitting ? <Alert variant={"info"} className={"mt-2"}><small>Your {formStore.name} form is complete, all the data has been saved. You can now scroll up and submit your application.</small></Alert> : null}
        {isInvalid ? <Alert variant={"danger"} className={"mt-2"}><small>Can't save. Check form validity.</small></Alert> : null}
      </>;
    }}
  </SkFormStoreChecker>;
};

export default SkFormBlockSubmitButton;
