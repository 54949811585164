import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import Collapse, {CollapseBag} from "../Collapse";
import SkForm, {isSkFieldValuesEqual, SkFormValues} from "./SkForm";
import useBag, {BagPasser} from "../../helpers/hooks/useBag";
import useBagPass from "../../helpers/hooks/useBagPass";
import {observer} from "mobx-react-lite";
import {runInAction, toJS} from "mobx";
import {FormikHelpers} from "formik/dist/types";
import {CheckCircleFill} from "react-bootstrap-icons";
import {ApiApplicationFormStore} from "../../helpers/factory/StoreWithFormsFromData";
import {SkFormMetaContext, SkFormPropsContext, SkFormStoreContext} from "./SkFormContexts";
import SkFormBlockSubmitButton from "./SkFormBlockSubmitButton";
import { useGraduationStepField } from "../../stores/GraduationStepFieldStore";
import { ApiQuestionRenderedModel } from "../../services/api/questions/ApiQuestionModel";

export interface SkFormBlockBag {
  collapseBag: MutableRefObject<CollapseBag|undefined>;
  scrollTo: () => void;
}

const SkFormBlock:React.FC<{
  form: ApiApplicationFormStore;
  applicationId: string;
  collapse: boolean;
  onSubmit: (values: SkFormValues, formikHelpers: FormikHelpers<SkFormValues>) => void;
  passBag?: BagPasser<SkFormBlockBag>;
}> = observer(({
  form,
  applicationId,
  collapse,
  onSubmit,
  passBag,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [collapseBagRef, passCollapseBag] = useBag<CollapseBag>();
  const graduationFieldStore = useGraduationStepField()
  const [filteredQuestions, setFilteredQuestions] = useState([...form.questions])

  useEffect(() => {
    if (form.id === "a875b945-6eab-4967-91ed-7251c3ed7ced") {
      if (graduationFieldStore.graduationStep === "M") {
        setFilteredQuestions(form.questions.filter(q => q.name !== "Research proposal"))
      }
      if (graduationFieldStore.graduationStep === "A") {
        const copyFilteredQuestions = [...filteredQuestions]
        const idx = copyFilteredQuestions.findIndex((q) => q.name === "Research proposal")
        copyFilteredQuestions.splice(idx, 1)
        setFilteredQuestions([...copyFilteredQuestions, {...form.questions.find(q => q.name === "Research proposal") as ApiQuestionRenderedModel, answers_count_min: 1}])
      }
      collapseBagRef.current?.collapse()
    }
  }, [graduationFieldStore.graduationStep])


  // Collapse form when fullfilled
  useEffect(() => {
    form.fulfilled && collapseBagRef.current?.collapse();
  }, [collapseBagRef, form.fulfilled]);

  useBagPass<SkFormBlockBag>(passBag, {
    collapseBag: collapseBagRef,
    scrollTo: () => ref.current?.scrollIntoView()
  });

  const handleValuesChange = (values: SkFormValues) => {
    console.log(values)
    if (values["868adfe4-1ab8-43a3-93dd-86b99ea430c0"] === "b6375082-1de8-4646-9f7f-c55399e84c17") {
      graduationFieldStore.setGraduationStep("M")
    }
    if (values["868adfe4-1ab8-43a3-93dd-86b99ea430c0"] === "c8cefedc-c10d-429b-9ef3-fc03b49a0198") {
      graduationFieldStore.setGraduationStep("A")
    }
    runInAction(() => {
      const changedQuestions = Object.keys(values).filter((key) => !isSkFieldValuesEqual(values[key], form.initialValues[key]));
      form.changedQuestions.replace(changedQuestions);
      form.questionsChanged = changedQuestions.length;
    });
  };

  return <div ref={ref} key={form.id}>
    <SkFormMetaContext.Provider value={{
      form: form.id,
      application: applicationId,
    }}>
      <SkFormPropsContext.Provider value={{
        reactiveInitialValues: toJS(form.initialValues),
        questions: toJS(filteredQuestions),
        questionsDisabled: form.root.questions_readonly,
      }}>
        <SkFormStoreContext.Provider value={{...form, questions: filteredQuestions}}>
          <Collapse className={"mb-4"} collapse={collapse} passBag={passCollapseBag}>
            {() => [
              <>
                {form.name ? form.name : <>Form "{form.slug}"</>}
                {form.fulfilled && <CheckCircleFill className={"ml-4 align-middle text-success"}/>}
              </>,
              <SkForm
                key={form.id}
                onSubmit={onSubmit}
                onValuesChange={handleValuesChange}
                mandatory={"noone"}
                submitComponent={SkFormBlockSubmitButton}
              />
            ]}
          </Collapse>
        </SkFormStoreContext.Provider>
      </SkFormPropsContext.Provider>
    </SkFormMetaContext.Provider>
  </div>;
});

export default SkFormBlock;
