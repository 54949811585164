import React, {useContext} from "react";
import {makeAutoObservable, reaction, runInAction} from "mobx";
import {Log} from "../helpers/log";
import tokenStore, {JWTVerify} from "./singletones/TokenStore";
import {Location, UnregisterCallback} from "history";
import { AxiosResponse } from "axios";
import User from "./models/User";
import JWTPayload from "../interfaces/jwt/JWTPayload";
import {ApiTokenObtain} from "../services/api/token/ApiTokenObtain";
import {ApiUsersMe, APIUsersMeResponse, APIUsersMeResponseSchema} from "../services/api/users/ApiUsersMe";
import {applicationsStore} from "./singletones/ApplicationsStore";
import {SkApplyHistory} from "../const/SkApplyHistory";

class GraduationStepFieldStore {
  graduationStep: any = null

  constructor() {
    makeAutoObservable(this);
    this.graduationStep = null;
  }

  setGraduationStep (graduationStep: string) {
    this.graduationStep = graduationStep
  }

}

export const graduationStepFieldStore = new GraduationStepFieldStore();

// auth helpers
export const GraduationStepFieldContext = React.createContext<GraduationStepFieldStore>(graduationStepFieldStore);
export const useGraduationStepField = () => useContext(GraduationStepFieldContext);
