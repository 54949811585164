import React, {useCallback, useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Pagination,Navigation, Keyboard} from "swiper/core";

import TourImg1 from "../img/tour/1.png";
import TourImg2 from "../img/tour/2.png";
import TourImg3 from "../img/tour/3.png";
import TourImg4 from "../img/tour/4.png";
import TourImg5 from "../img/tour/5.png";
import TourImg6 from "../img/tour/6.png";
import TourImg7 from "../img/tour/7.png";
import TourImg8 from "../img/tour/8.png";
import {XCircle} from "react-bootstrap-icons";
import {ApiApplicationIdVariablesGet} from "../services/api/applications/ApiApplicationIdVariablesGet";
import {observer} from "mobx-react-lite";
import {ApiApplicationIdVariablesPost} from "../services/api/applications/ApiApplicationIdVariablesPost";

SwiperCore.use([Pagination,Navigation,Keyboard]);

const SwiperTour:React.FC<{
  id: string;
}> = observer(({id}) => {
  useEffect(() => {
    if (id) {
      ApiApplicationIdVariablesGet(id).then((response) => {
        if (response.status === 200) {
          if (response.data.find(variable => variable.name === "tour_skip")?.value !== "true") {
            setShow(true);
          }
        }
      }, e => console.error(e));
    }
  }, [id]);

  const [show, setShow] = useState(false);
  const handleClose = useCallback((e) => {
    e.preventDefault();
    ApiApplicationIdVariablesPost(id, [{
      name: "tour_skip",
      value: "true"
    }]).catch(e => console.error(e));
    setShow(false);
  }, [id]);

  if (show) return <div className={"swiper-tour"}>
    <div className="swiper-tour-container">
      <div className="swiper-tour-skip">
        <a href="#" onClick={handleClose}>
          <span className={"mr-3"}>Skip</span><XCircle/>
        </a>
      </div>
      <Swiper
        keyboard={{"enabled": true}}
        autoHeight={true}
        slidesPerView={1}
        pagination={{type: "progressbar"}}
        navigation={true}
        cssMode={true}
      >
        <SwiperSlide><img src={TourImg1} alt={"tour-1"}/></SwiperSlide>
        <SwiperSlide><img src={TourImg2} alt={"tour-2"}/></SwiperSlide>
        <SwiperSlide><img src={TourImg3} alt={"tour-3"}/></SwiperSlide>
        <SwiperSlide><img src={TourImg4} alt={"tour-4"}/></SwiperSlide>
        <SwiperSlide><img src={TourImg5} alt={"tour-5"}/></SwiperSlide>
        <SwiperSlide><img src={TourImg6} alt={"tour-6"}/></SwiperSlide>
        <SwiperSlide><img src={TourImg7} alt={"tour-7"}/></SwiperSlide>
        <SwiperSlide>
          <img
            src={TourImg8}
            onClick={handleClose}
            className={"swiper-tour-close"}
            alt={"tour-finish"}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>;

  else return null;
});

export default SwiperTour;