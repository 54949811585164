import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {useAuth} from "../stores/AuthStore";
import {observer} from "mobx-react-lite";
import Logo from "../components/Logo";
import {ButtonProps, Container, Row, Col, Dropdown, Card} from "react-bootstrap";
import WithDefaults from "./withs/WithDefaults";
import ApplicationPageStore from "../stores/pages/ApplicationPageStore";
import Loading from "./Loading";
import {SkFormValues} from "../components/SkForms/SkForm";
import ErrorDisplay from "./ErrorDisplay";
import {Log} from "../helpers/log";
import useBags from "../helpers/hooks/useBags";
import SkFormBlock, {SkFormBlockBag} from "../components/SkForms/SkFormBlock";
import {FormikHelpers} from "formik/dist/types";
import {ApiQuestionRenderedModel} from "../services/api/questions/ApiQuestionModel";
import processErrors from "../components/SkForms/processErrors";
import {runInAction} from "mobx";
import ApplicationPageStickyBlock from "../components/ApplicationPageStickyBlock";
import {ApiApplicationFormStore} from "../helpers/factory/StoreWithFormsFromData";
import ErrorBoundary from "../components/ErrorBoundary";
import SwiperTour from "../components/SwiperTour";

// eslint-disable-next-line react/display-name
const SkNavDropdownToggle:React.FC = React.forwardRef<HTMLAnchorElement, ButtonProps>((props, ref) => {
  return <a href="#" className="dropdown-toggle btn btn-sk-nav" onClick={props.onClick} ref={ref}>
    <span className="sk-navigation-ellipsis">{props.children}</span>
  </a>;
});

const ApplicationPage: React.FC = observer(() => {
  const log = new Log("ApplicationPage");

  const applicationPageStore = useMemo(() => new ApplicationPageStore(true), []);

  // a reference to use
  const forms = applicationPageStore.model?.forms;

  // bags for forms actions (scrolling right now)
  const [formBagsMap, passFormBagFactory] = useBags<SkFormBlockBag>();
  const scrollToForm = (id: string) => {
    try {
      const bag = formBagsMap.get(id);
      if (bag) {
        bag.collapseBag && bag.collapseBag.current?.expand();
        bag.scrollTo();
      } else {
        window.scrollTo({left: 0, top: 0});
      }
    } catch (e) {
      log.error(e);
    }
  };

  // scroll to form when expanded form changed
  const [prevExpandedForm, setPrevExpandedForm] = useState(applicationPageStore.expandedForm);
  useEffect(() => {
    if (prevExpandedForm !== applicationPageStore.expandedForm) {
      setPrevExpandedForm(applicationPageStore.expandedForm);
      scrollToForm(applicationPageStore.expandedForm);
    }
  }, [applicationPageStore.expandedForm]);

  const auth = useAuth();
  const userActionsTitle = auth.user ? auth.user.firstName ? auth.user.firstName : auth.user.username : "Actions";
  const handleLogout:React.MouseEventHandler = () => {
    auth.logout();
  };

  const handleFormSubmit = (form: string, questions: ApiQuestionRenderedModel[], values: SkFormValues, formikHelpers: FormikHelpers<SkFormValues>) => {
    formikHelpers.setStatus("");
    applicationPageStore.submitAnswers(form,values).then(() => {
      formikHelpers.setSubmitting(false);
    }).catch((e) => {
      const [noneFieldError, fieldErrors] = processErrors(e, values, questions);
      formikHelpers.setSubmitting(false);
      formikHelpers.setErrors(fieldErrors);
      formikHelpers.setStatus(noneFieldError);
    });
  };

  const handleApplicationSubmit = () => {
    runInAction(() => {
      applicationPageStore.submitError = "";
    });

    applicationPageStore.submit().catch((e) => {
      const [noneFieldError] = processErrors(e);
      runInAction(() => {
        applicationPageStore.submitError = noneFieldError;
      });
    });
  };

  return (
    <ErrorBoundary errorLevel={"ApplicationPage"}>
      <WithDefaults chatraId={applicationPageStore.applicationId ? applicationPageStore.applicationId : undefined}>
        <div className="sk-navigation-container">
          <div className="sk-navigation">
            <div className="sk-navigation-brand">
              <Logo/>
            </div>
            <div className="sk-navigation-action">
              <Dropdown>
                <Dropdown.Toggle as={SkNavDropdownToggle} id="dropdown-user-actions">
                  {userActionsTitle}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#chatraChatExpanded">Contact support</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={handleLogout}>Sign out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        <Loading loading={applicationPageStore.state === "init"}>
          <ErrorDisplay error={applicationPageStore.error}>

            <ApplicationPageStateRouter store={applicationPageStore}>{() => [
              <Container className={"mt-4 mb-4"} key={"noapp"}>
                <Row>
                  <Col>
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          No applications!
                        </Card.Title>
                        You can apply a new one from a Home page. <a href="#" onClick={handleLogout}>Sign out now and apply.</a>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>,
              <Container className={"mt-4 mb-4"} key={applicationPageStore.applicationId}>
                <Row>
                  <Col lg={12} xl={6}>
                    <ApplicationPageStickyBlock
                      applicationPageStore={applicationPageStore}
                      handleApplicationSubmit={handleApplicationSubmit}
                      scrollToForm={scrollToForm}
                    />
                  </Col>

                  <Col lg={12} xl={6}>
                    <Loading loading={applicationPageStore.state === "submit"}>
                      {forms && forms.map((form: ApiApplicationFormStore) =>
                        <SkFormBlock
                          key={`${form.id}_${applicationPageStore.expandedForm}`} // FOR REDRAW WHEN FULLFILLED CHANGE
                          form={form}
                          onSubmit={(v,f) => handleFormSubmit(form.id, form.questions, v, f)}
                          collapse={applicationPageStore.expandedForm !== form.id}
                          passBag={passFormBagFactory(form.id)}
                          applicationId={applicationPageStore.applicationId}
                        />)}
                    </Loading>

                    <div className="footer"><a className="small" href="https://box.skoltech.ru/index.php/s/dI9uoKolWX9McDE">Skoltech Selection Regulations</a></div>
                  </Col>
                </Row>
              </Container>
            ]}</ApplicationPageStateRouter>


          </ErrorDisplay>
        </Loading>
        <SwiperTour id={applicationPageStore.applicationId}/>
      </WithDefaults>
    </ErrorBoundary>
  );
});

const ApplicationPageStateRouter:React.FC<{
  store: ApplicationPageStore;
  children: () => [ReactElement, ReactElement];
}> = observer((props) => {
  const [empty, apply] = props.children();

  if (props.store.state === "empty") {
    return empty;
  } else {
    return apply;
  }
});

export default ApplicationPage;
