import React from "react";

export const PaidProvidionAgreement: React.FC<{
    termsAccepted: boolean;
    setTermsAccepted: (termsAccepted: boolean) => void;
}> = ({
          termsAccepted, setTermsAccepted
      }) => <div className="form-check">
    <input
        id={`accepts_pay_terms`}
        className="form-check-input"
        type="checkbox"
        checked={termsAccepted}
        onChange={() => setTermsAccepted(!termsAccepted)}
    />
    <label className="form-check-label" htmlFor="form-check-input">
        I accept <a href="https://box.skoltech.ru/index.php/s/fa5HIUyPKje7NxB" target="_blank" rel="noreferrer">The
        Offer Agreement of the paid provision of service</a>
    </label>
</div>