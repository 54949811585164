import {Schema} from "yup";
import * as yup from "yup";
import pluralize from "pluralize";
import {ApiQuestionRenderedModel} from "../../services/api/questions/ApiQuestionModel";
import {s2positivei} from "../utils";
import {SkSelectValuesFromApiVariants} from "../../components/SkForms/SkForm";

const REACT_APP_SELECT_AUTOSUGGEST_FROM_ITEMS_GTE = s2positivei(process.env.REACT_APP_SELECT_AUTOSUGGEST_FROM_ITEMS_GTE, 5);

export type MandatoryMode = "all" | "noone" | "auto";

const getNonLatinString = () => yup.string().test("is-cyrillic", "Please use only Latin letters", (value) => (`${value}`.match(/[А-яё]/gm)) === null);

const ValidationSchemaFromQuestions = (questions:ApiQuestionRenderedModel[], mandatoryMode: MandatoryMode = "auto") => {
  const schemaShape: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [questionId: string]: Schema<any>;
  } = {};

  questions.forEach(q => {
    const mandatory = mandatoryMode === "all" ? true : mandatoryMode === "noone" ? false : q.answers_count_min > 0;
    let questionRule;
    if (q.display_type === "selectfree" || q.display_type === "select") {
      questionRule = getNonLatinString();
      if (q.tag === "educational_level" || q.tag === "educational_program" || q.tag === "gender" || q.tag === "hear_source" || q.tag === "research_proposal") {
        questionRule = questionRule.required(`${q.name} is required.`);
      }
      if (q.display_type === "select" && !q.disable_autocomplete && q.variants.length > REACT_APP_SELECT_AUTOSUGGEST_FROM_ITEMS_GTE) {
        const variants = SkSelectValuesFromApiVariants(q);
        questionRule = questionRule.oneOf(Object.keys(variants), "Incorrect value. Please, pick one of the suggested values.");
        questionRule = questionRule.required(`${q.name} is required.`);
      }
      if (mandatory && q.dependency_resolution !== "visibility") {
        questionRule = questionRule.required(`${q.name} is required.`);
      }
    } else if (q.display_type === "email") {
      questionRule = getNonLatinString().email("Please enter correct email");
      if (mandatory) {
        questionRule = questionRule.required(`${q.name} is required`);
      }
    } else if (q.display_type === "number") {
      questionRule = yup.string().matches(/^[\d,]+$/, "Please enter correct number");
      if (mandatory) {
        questionRule = questionRule.required(`${q.name} is required`);
      }
    } else if (q.display_type === "out_of") {
      questionRule = yup.string()
        // .matches(/^[\d,]+\/[\d,]+$/, "Please enter both correct numbers")
        .matches(/^[\d,]+\/[\d,]/, "Please enter both correct numbers")
        .test("out-of-is-not-too-big", "Average can not be more than maximum", (value) => (parseFloat(`${value}`.split("/")[0].replace(",", ".")) <= parseFloat(`${value}`.split("/")[1].replace(",", "."))));
      if (mandatory) {
        questionRule = questionRule.required(`${q.name} is required`);
      }
    } else if (q.display_type === "upload") {
      questionRule = yup.array();
      questionRule = questionRule.min(q.answers_count_min, `You need to attach at least ${q.answers_count_min} ${pluralize("file", q.answers_count_min)}`);

      if (q.answers_count_max > 0) {
        questionRule = questionRule.max(q.answers_count_max, `You cannot attach more than ${q.answers_count_max} ${pluralize("file", q.answers_count_max)}`);
      }
    } else if(q.tag === "name" || q.tag === "surname" || q.tag === "country_of_citizenship" || q.tag === "country_of_living" || q.tag === "city_of_living" || q.tag === "research_proposal") {
      questionRule = yup.string().matches(/^[A-Za-z]+ig/, `Please enter both correct ${q.name}`);
      questionRule = getNonLatinString();
      questionRule = questionRule.required(`${q.name} is required`);
    }
    else {
      questionRule = getNonLatinString();
      if (mandatory) {
        questionRule = questionRule.required(`${q.name} is required`);
      }
    }

    schemaShape[q.id] = questionRule;
  });

  return yup.object().shape(schemaShape);
};

export default ValidationSchemaFromQuestions;
