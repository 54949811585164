import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {PaidProvidionAgreement} from "./PaidProvidionAgreement";

export const PayButton:React.FC<{
    url: string;
}> = ({url}) => {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const className = "t-4 mb-4 btn btn-primary btn-block btn-lg";
    const caption = "Pay processing fee";

    return <>
        {termsAccepted ? <a className={className} href={url}>{caption}</a> : <Button className={className} disabled>{caption}</Button>}
        <PaidProvidionAgreement termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted}/>
    </>;
}