/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useMemo} from "react";
import {Alert, Button, Card, ProgressBar, Spinner} from "react-bootstrap";
import ApplicationPageStore from "../stores/pages/ApplicationPageStore";
import {CheckCircleFill} from "react-bootstrap-icons";
import {runInAction} from "mobx";
import WithSticky from "../pages/withs/WithSticky";
import {observer} from "mobx-react-lite";
import {ApiApplicationFormStore} from "../helpers/factory/StoreWithFormsFromData";
import {CampaignNotificationStyleVariants} from "./backoffice/configs/NotificationsConfigs";
import {PayButton} from "./PayButton";
// import {PaidProvidionAgreement} from "./PaidProvidionAgreement";

const ApplicationPageStickyBlock:React.FC<{
  handleApplicationSubmit: () => void;
  scrollToForm: (id: string) => void;
  applicationPageStore: ApplicationPageStore
}> = observer(({
  applicationPageStore,
  handleApplicationSubmit,
  scrollToForm,
}) => {
  const forms = applicationPageStore.model?.forms;
  const formsReadonly = applicationPageStore.model?.forms_readonly;
  const showSubmitButton = useMemo(() => !!applicationPageStore.model?.show_submit_button, [applicationPageStore.model?.show_submit_button]);
  // const [termsAccepted, setTermsAccepted] = useState(false);

  const applicationSent = !showSubmitButton;

  const totalQuestions = applicationPageStore.model?.forms.reduce((a: number, form) => a + form.questions.length, 0) || 0;
  const totalQuestionsSubmitted = applicationPageStore.model?.forms.reduce((a: number, form) => a + form.questionsSubmitted, 0) || 0;
  const totalQuestionsMandatory = applicationPageStore.model?.forms.reduce((a: number, form) => a + form.questionsMandatory, 0) || 0;

  const overallProgress = Math.round(totalQuestionsSubmitted / totalQuestions * 100);

  const PBFormOverallProps = (form: ApiApplicationFormStore) => ({
    ...(form.fulfilled && {variant: "success"})
  });

  const PBOverallProps = {
    ...(totalQuestionsSubmitted >= totalQuestionsMandatory && {variant: "success"})
  };

  return (
    <WithSticky when={width => width >= 2048} topOffset={24} stickyClass={"sk-status-sticky"}>
      {applicationPageStore.model?.notifications.length ? applicationPageStore.model?.notifications.map((n,i) => {
        const variant = CampaignNotificationStyleVariants[n.style];

        return <Card
          key={`notification_${i}`}
          className={"mb-4"}
          {...(variant ? {
            bg: variant,
          } : {})}
          {...(variant === "danger" ? {
            text: "white",
          } : {})}
        >
          <Card.Body>
            <Card.Text>
              {n.text}
            </Card.Text>
          </Card.Body>
        </Card>;
      }) : null}

      {applicationSent ? (
        <Card
          bg={"light"}
          text={"dark"}
          className={"mb-4"}
        >
          <Card.Body>
            <Card.Title>
              Your application is sent!
            </Card.Title>
            <p>Your Skoltech selection application is being processed. We will be in touch via email about next steps of the selection process. Please make sure not to miss our e-mails.</p>
            <p>At the moment you can't make any changes to the data you've provided, you can only view it.</p>
            <p>Should you have any questions, feel free to contact us at admissions@skoltech.ru. See you!</p>
          </Card.Body>
        </Card>
      ) : (
        <>
          {/** блок с прогрессом */}
          {formsReadonly?.length !== forms?.length ? <Card
            bg={"light"}
            text={"dark"}
            className={"mb-4"}
          >
            <Card.Body>
              <Card.Title>
                Overall application progress
              </Card.Title>
              <ProgressBar>
                <ProgressBar now={overallProgress} key={1} {...PBOverallProps}/>
              </ProgressBar>
              <div className={"d-flex align-items-baseline mt-4 mb-2"}>
                <Card.Title className={"flex-grow-1"}>
                  Progress by forms
                </Card.Title>
                <small className="text-muted">Click form name to scroll</small>
              </div>
              {forms && forms.filter(form => formsReadonly ? !formsReadonly.includes(form.id) : false).map((form: ApiApplicationFormStore) => (
                <div className={"d-flex align-items-baseline mb-0"} key={form.id}>
                  <h6 className={"w-50 mr-4"}>
                    <a href="#" onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      scrollToForm(form.id);
                    }}
                    className={`${form.fulfilled && "text-success"}`}
                    >
                      {form.name} {form.fulfilled && <CheckCircleFill className={"ml-2 align-middle"}/>}
                    </a>
                  </h6>
                  <div className={"w-50"}>
                    <ProgressBar className={"mb-4"}>
                      {form.name === 'Profile' && <ProgressBar now={Math.round(form.questionsSubmitted / (form.questions.length - 1) * 100)} key={1} {...PBFormOverallProps(form)}/>}
                      {form.name === 'Education details' && <ProgressBar now={Math.round(form.questionsSubmitted / (form.questions.length - 2) * 100)} key={1} {...PBFormOverallProps(form)}/>}
                      {form.name === 'Professional details' && <ProgressBar now={Math.round(form.questionsSubmitted / (form.questions.length - 13) * 100)} key={1} {...PBFormOverallProps(form)}/>}
                    </ProgressBar>
                  </div>
                </div>
              ))}
            </Card.Body>
          </Card> : null}

          {showSubmitButton ? <>
            {!applicationPageStore.model?.can_be_submitted && (
              <p>
                <small>
                  To submit your application, please fill out all the required fields carefully and accurately. You may save your progress and return to your application any time.
                </small>
              </p>
            )}
            {applicationPageStore.model?.can_be_submitted && (totalQuestionsSubmitted >= totalQuestionsMandatory) && (
              <p className={"lead"}>
                You filled out all mandatory fields. From now on, your application can be submitted.
              </p>
            )}
            {applicationPageStore.model?.show_pay_button && (totalQuestionsSubmitted >= totalQuestionsMandatory) && (
              <p className={"lead"}>
                Please proceed with a processing fee payment.
              </p>
            ) && (
              <p className={"lead"}>
                <a href="https://box.skoltech.ru/index.php/s/UxQyDu59nzFUf3D" target="_blank" rel="noreferrer">Terms and conditions</a>
              </p>
            )}
            {applicationPageStore.submitError && <Alert
              variant="danger"
              onClose={() => runInAction(() => {
                applicationPageStore.submitError = "";
              })}
              dismissible
            >
              {applicationPageStore.submitError}
            </Alert>}
            {!applicationPageStore.model?.show_pay_button && (<>
              {/* <Button className={"mt-4 mb-4"} variant={"primary"} size={"lg"} block disabled={!termsAccepted || !applicationPageStore.model?.can_be_submitted} onClick={handleApplicationSubmit}>
                {applicationPageStore.state === "submit"
                    ? <><Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    /></>
                    : <>Submit Application</>
                }
              </Button> */}
              <Button className={"mt-4 mb-4"} variant={"primary"} size={"lg"} block disabled={!applicationPageStore.model?.can_be_submitted} onClick={handleApplicationSubmit}>
                {applicationPageStore.state === "submit"
                    ? <><Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    /></>
                    : <>Submit Application</>
                }
              </Button>
              {/* <PaidProvidionAgreement termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted}/> */}
            </>)}
            {applicationPageStore.model?.show_pay_button && !!applicationPageStore.model?.pay_button_url && (
                <PayButton url={applicationPageStore.model?.pay_button_url}/>
            )}
          </> : null}
        </>
      )}
    </WithSticky>
  );
});

export default ApplicationPageStickyBlock;
